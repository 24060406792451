import { Input, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardModelosCotizadosDetalleModeloComponent } from '../dashboard-modelos-cotizados-detalle-modelo/dashboard-modelos-cotizados-detalle-modelo.component';
@Component({
  selector: 'app-dashboard-modelos-cotizados',
  templateUrl: './dashboard-modelos-cotizados.component.html',
  styleUrls: ['./dashboard-modelos-cotizados.component.scss']
})
export class DashboardModelosCotizadosComponent implements OnInit {



  @Input() single = []


  view: any[] = null

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Modelo';
  showYAxisLabel = true;
  yAxisLabel = 'Leads';

  colorScheme = {
    domain: ['#f26171', '#f04255', '#ff102a', '#aa0013', '#67020d']
  };

  constructor(
    private modalService: NgbModal
  ) {
  }

  onSelect(event) {
    if (event.name) {
      this.abrirDetallePorModelo(event.name)
    }
  }


  abrirDetallePorModelo(modelo) {
    const dialogRef = this.modalService.open(DashboardModelosCotizadosDetalleModeloComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = JSON.parse(localStorage.getItem('user')).jwt
    dialogRef.componentInstance.modelo = modelo

    dialogRef.result.then((data) => {
      console.log(data)
    })
  }

  ngOnInit(): void {
  }

}
