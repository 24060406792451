<div *ngIf="tienda_seleccionada >= 0; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
    <app-breadcrumb [title]="'Leads'" [items]="['Registros', 'Leads Comerciales']"
        [active_item]="nombre_tienda_seleccionada">
    </app-breadcrumb>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="container contenedor-leads">
                        <div class="row detalle-leads">
                            <h5 class="m-t-5" *ngIf="user_type_id == 3">Concesionario : {{campus_name}}</h5>
                            <div class="row resumen-leads">
                                <div class="col-6 col-sm-6 col-xl-4 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-frio b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'cloud-snow'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Fríos</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.frios}}" from="0" duration="1">
                                                        {{detallado_leads.frios}}</h4>
                                                    <i class="icon-bg" data-feather="cloud-snow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xl-4 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-tibio b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'cloud-lightning'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Tibios</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.tibios}}" from="0" duration="1">
                                                        {{detallado_leads.tibios}}</h4>
                                                    <i class="icon-bg" data-feather="cloud-lightning"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xl-4 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-caliente b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'coffee'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Caliente</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.calientes}}" from="0" duration="1">
                                                        {{detallado_leads.calientes}}</h4>
                                                    <i class="icon-bg" data-feather="coffee"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xl-4 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-pendiente b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'loader'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Pendiente</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.pendientes}}" from="0" duration="1">
                                                        {{detallado_leads.pendientes}}</h4>
                                                    <i class="icon-bg" data-feather="loader"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xl-4 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-venta b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'dollar-sign'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Venta</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.ventas}}" from="0" duration="1">
                                                        {{detallado_leads.ventas}}</h4>
                                                    <i class="icon-bg" data-feather="dollar-sign"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xl-4 col-lg-6">
                                    <div class="card o-hidden">
                                        <div class="bg-total b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'users'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Total</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.total}}" from="0" duration="1">
                                                        {{detallado_leads.total}}</h4>
                                                    <i class="icon-bg" data-feather="users"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card o-hidden">
                                        <div class="bg-reprogramado b-r-4 card-body">
                                            <div class="media static-top-widget">
                                                <div class="align-self-center text-center">
                                                    <app-feather-icons [icon]="'clock'"></app-feather-icons>
                                                </div>
                                                <div class="media-body"><span
                                                        class="nombre-estado-cliente m-0">Reprogramados</span>
                                                    <h4 class="number-estado-cliente mb-0 counter"
                                                        CountTo="{{detallado_leads.reprogramados}}" from="0"
                                                        duration="1">
                                                        {{detallado_leads.reprogramados}}</h4>
                                                    <i class="icon-bg" data-feather="clock"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card-body card-body-filtros">
                                    <div class="buttons">
                                        <button type="button" class="btn btn-primary-honda button_filter__collapse"
                                            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                                            aria-controls="collapseExample">
                                            <span>Filtros</span>
                                            <app-feather-icons [icon]="'filter'"></app-feather-icons>

                                        </button>
                                        <button *ngIf="user_type_id != 2" type="button"
                                            class="btn btn-venta button_filter__collapse" (click)="exportar()"
                                            aria-controls="collapseExample">
                                            <span>Exportar</span>
                                            <app-feather-icons [icon]="'file-text'"></app-feather-icons>
                                        </button>
                                        <button *ngIf="user_type_id != 2" type="button"
                                            class="btn btn-warning button_filter__collapse" (click)="reasignar()"
                                            aria-controls="collapseExample">
                                            <span>Reasignar</span>
                                            <app-feather-icons [icon]="'file-text'"></app-feather-icons>
                                        </button>
                                    </div>
                                    <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                        <div class="card mb-0">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h5><u>Filtros</u></h5>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Fecha de
                                                                inicio</label>
                                                            <div class="col-sm-9">
                                                                <input class="form-control digits"
                                                                    [(ngModel)]="filtros.fecha_inicio"
                                                                    id="example-datetime-local-input" type="date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Fecha de fin</label>
                                                            <div class="col-sm-9">
                                                                <input class="form-control digits"
                                                                    [(ngModel)]="filtros.fecha_fin"
                                                                    id="example-datetime-local-input" type="date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">¿Respondió llamada?</div>
                                                            <ng-select [items]="default_values.respondio_llamada"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.respondio_llamada"
                                                                [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Estado del cliente</div>
                                                            <ng-select [items]="default_values.estado_cliente"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.estado_cliente" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Modelo de moto</div>
                                                            <ng-select [items]="default_values.modelo"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.modelo" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6" *ngIf="user_type_id != 2">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Seleccione el vendedor</div>
                                                            <ng-select [items]="default_values.vendedores"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.vendedores" bindValue="id"
                                                                bindLabel="name" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Seleccione plataforma</div>
                                                            <ng-select [items]="default_values.plataforma"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.plataforma" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Seleccione formulario (Landing)
                                                            </div>
                                                            <ng-select [items]="default_values.fuente"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.fuente" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Tipo de filtro</div>
                                                            <ng-select [items]="default_values.tipo_filtro"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.tipo_filtro" [multiple]="false">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6" *ngIf="tienda_seleccionada <= 0">
                                                        <div class="form-group">
                                                            <div class="col-form-label">Región</div>
                                                            <ng-select [items]="default_values.region"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.region" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <h5><u>Filtros UTMS</u></h5>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">UTM Source</div>
                                                            <ng-select [items]="default_values.utm_source"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.utm_source" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">UTM Medium</div>
                                                            <ng-select [items]="default_values.utm_medium"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.utm_medium" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">UTM Campaign</div>
                                                            <ng-select [items]="default_values.utm_campaign"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.utm_campaign" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <div class="col-form-label">UTM Term/Content</div>
                                                            <ng-select [items]="default_values.utm_term"
                                                                class="js-example-basic-multiple" placeholder=""
                                                                [(ngModel)]="filtros.utm_term" [multiple]="true">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <h5><u>Filtros adicionales</u></h5>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <div class="media">
                                                                    <label class="col-form-label m-r-10">¿Es
                                                                        financiamiento?</label>
                                                                    <div class="media-body text-end icon-state">
                                                                        <label class="switch">
                                                                            <input
                                                                                [value]="default_values.financiamiento"
                                                                                [(ngModel)]="filtros.financiamiento"
                                                                                type="checkbox"><span
                                                                                class="switch-state"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group">
                                                                <div class="form-group">
                                                                    <div class="col-form-label">Por texto</div>
                                                                    <input class="form-control" type="text"
                                                                        [(ngModel)]="filtros.texto" placeholder="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 div-button_filter">
                                                        <button id="make_filter" type="button" class="btn btn-primary"
                                                            (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                                            aria-controls="collapseExample"
                                                            [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                                            <span>{{buttonFiltrarTexto}}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="card-body custom-datatable noscroll table-card-container">
                                    <div class="custom-datatable table-responsive">
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                            class="row-border hover table table-leads pulpa-datatable-responsive nowrap display">
                                            <thead>
                                                <tr>
                                                    <th *ngFor="let cabecera of tableCabeceras">{{cabecera.name}}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="persons?.length != 0">
                                                <tr *ngFor="let lead of persons" class="tr-mobile">
                                                    <div class="imagen-container">
                                                        <img class="imagen-moto" src="{{lead.moto_imagen}}"
                                                            alt="Imagen de {{lead.auto_modelo}}">
                                                    </div>
                                                    <th *ngFor="let cabecera of tableCabeceras">

                                                        <div class="descripcion">{{cabecera.name}}</div>
                                                        <container-element [ngSwitch]="cabecera.id">

                                                            <some-element class="mobile-detail" *ngSwitchCase="1">
                                                                <span
                                                                    class="badge rounded-pill {{lead.bg_class}}">{{lead.estado_cliente | uppercase}}</span>
                                                                <input *ngIf="user_type_id != 2" class="checkbox-pulpa"
                                                                    type="checkbox" [checked]="lead.checked"
                                                                    value="{{lead.id}}" (change)="select_lead($event)">

                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="2">
                                                                {{lead.nombres}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="3">
                                                                {{lead.celular}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="4">
                                                                {{lead.correo_electronico}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="5">
                                                                {{lead.dni}}</some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="6">
                                                                {{lead.created_time}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="7">
                                                                {{lead.plataforma}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="8">
                                                                <img class="imagen-moto-desktop"
                                                                    src="{{lead.moto_imagen}}"
                                                                    alt="Imagen de moto {{lead.modelo}}">
                                                                {{lead.modelo}}
                                                            </some-element>
                                                            <some-element class="mobile-detail" *ngSwitchCase="9">
                                                                <a [routerLink]="[lead.id]"><i
                                                                        class="fa fa-arrow-circle-right lead-option"></i></a>
                                                            </some-element>
                                                        </container-element>
                                                    </th>
                                                    <!-- <th>
                                                        <div class="d-none d-sm-block d-md-none">Estado:</div>
                                                        <span class="badge rounded-pill {{lead.bg_class}}">{{lead.estado_cliente | uppercase}}</span>
                                                    </th>
                                                    <th>
                                                        <div class="d-none d-sm-block d-md-none">Nombres:</div>
                                                        <div>{{lead.nombres}}</div>
                                                    </th>
                                                    <th *ngIf="user_type_id != 2">{{lead.celular}}</th>
                                                    <th *ngIf="user_type_id != 2">{{lead.correo_electronico}}</th>
                                                    <th>{{lead.dni}}</th>
                                                    <th>{{lead.created_time}}</th>
                                                    <th>{{lead.plataforma}}</th>
                                                    <th>{{lead.modelo}}</th>
                                                    <th>
                                                        <a [routerLink]="[lead.id]"><i
                                                                class="fa fa-arrow-circle-right"></i></a>
                                                    </th> -->
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="persons?.length == 0">
                                                <tr>
                                                    <td colspan="3" class="no-data-available">¡Cargando!</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<ng-template #elseBlock>
    <app-breadcrumb [title]="''" [items]="['Registros', 'Leads Comerciales']"
        [active_item]="'Seleccione concesionario'">
    </app-breadcrumb>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>¡Seleccione concesionario!</h5><span>Debe elegir un concesionario para ver los leads.</span>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-concesionarios">
                            <thead>
                                <tr>
                                    <th scope="col">Región</th>
                                    <th scope="col">Departamento</th>
                                    <th scope="col">Provincia</th>
                                    <th scope="col">Nombre del concesionario</th>
                                    <th scope="col">Ir</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tienda of tiendas">
                                    <th scope="row">{{tienda.region}}</th>
                                    <td>{{tienda.departamento_id}}</td>
                                    <td>{{tienda.provincia_id}}</td>
                                    <td>{{tienda.name}}</td>
                                    <td>
                                        <a [routerLink]="[tienda.id]"><i class="fa fa-arrow-circle-right"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>