import { Input, Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

const Swal = require('sweetalert2')
@Component({
  selector: 'app-modal-create-lead',
  templateUrl: './modal-create-lead.component.html',
  styleUrls: ['./modal-create-lead.component.scss']
})
export class ModalCreateLeadComponent implements OnInit {

  @Input() private jwt

  user_type_id

  sellers_disponibles = []

  lead_to_create = {
    concesionario_id: '',
    nombres: '',
    correo_electronico: '',
    celular: '',
    dni: '',
    modelo: '',
    fecha_estimada_compra: '',
    comentarios: '',
    seller_id: 0
  }

  fecha_estimada_de_compra = [
    {
      id: 'De 1 a 2 meses',
      name: 'De 1 a 2 meses'
    },
    {
      id: 'Lo mas pronto posible',
      name: 'Lo mas pronto posible'
    },
    {
      id: 'De 7 meses a más',
      name: 'De 7 meses a más'
    },
    {
      id: 'Mas de 2 meses',
      name: 'Mas de 2 meses'
    },
    {
      id: '15_días',
      name: '15 días'
    },
    {
      id: '1_mes',
      name: '1 mes'
    },
    {
      id: '2_meses',
      name: '2 meses'
    },
    {
      id: 'más_de_2_meses',
      name: 'Más de 2 meses'
    },
  ]


  concesionarios: []
  modelos: []

  is_loading = false

  constructor(
    private http: HttpClient,
    private activeModalService: NgbActiveModal
  ) {
    this.user_type_id = JSON.parse(localStorage.user).user_type_id
  }

  async ngOnInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/create_lead_init`, data))
    if (response.success) {
      this.concesionarios = response.campus_assigned
      this.modelos = response.modelos
    }
  }

  onCancel() {
    this.activeModalService.close({
      'success': false
    });
  }

  onSubmit() {
    console.log(this.lead_to_create)
    if (this.validar()) {
      this.is_loading = true
      this.grabar()
    } else {
      console.log('no')
    }
  }

  async grabar() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    data.append('lead_to_create', JSON.stringify(this.lead_to_create))
    if(this.user_type_id != 2){
      data.append('is_admin', 1 + '')
    }
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/create_lead`, data))
    if (response.success) {
      this.activeModalService.close(response);
    }
  }

  validar() {
    if (!this.lead_to_create.nombres) {
      Swal.fire('Ingrese nombres del cliente', '', 'error')
      return false
    }

    if (!this.lead_to_create.correo_electronico) {
      Swal.fire('Ingrese correo electrónico del cliente', '', 'error')
      return false
    } else if (!this.validar_correo(this.lead_to_create.correo_electronico)) {
      Swal.fire('Ingrese correo electrónico válido', '', 'error')
      return false
    }

    if (!this.validar_celular(this.lead_to_create.celular)) {
      Swal.fire('Ingrese número de celular válido', '', 'error')
      return false
    }
    if (!this.validar_dni(this.lead_to_create.dni)) {
      Swal.fire('Ingrese DNI válido', '', 'error')
      return false
    }

    if (!this.lead_to_create.fecha_estimada_compra) {
      Swal.fire('Ingrese la fecha estimada de compra', '', 'error')
      return false
    }

    if (!this.lead_to_create.modelo) {
      Swal.fire('Ingrese el modelo de interés del cliente', '', 'error')
      return false
    }
    if (!this.lead_to_create.concesionario_id) {
      Swal.fire('Ingrese el concesionaro', '', 'error')
      return false
    }

    if (this.user_type_id != 2) {
      if (!this.lead_to_create.seller_id) {
        Swal.fire('Debes seleccionar a un vendedor', '', 'error')
        return false
      }
    }

    return true
  }

  validar_celular = (celular) => {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length >= 9) {
          return true
        }
      }
    }
    return false
  }

  validar_dni = (dni) => {
    if (dni) {
      if (this.isNumber(dni)) {
        if (dni.length == 8) {
          return true
        }
      }
    }
    return false
  }
  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }


  validar_correo = (correo) => {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (correo) {
      if (cadena.test(correo)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }


  look_for_sellers() {
    if (this.user_type_id != 2) {
      this.get_sellers_dispo()
    }
  }

  async get_sellers_dispo() {
    this.sellers_disponibles = []
    this.lead_to_create.seller_id = 0
    if (this.lead_to_create.concesionario_id) {
      const data = new FormData()
      data.append('jwt', this.jwt)
      data.append('campus_id', this.lead_to_create.concesionario_id)
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}CampusAPI/get_sellers_by_conce_id`, data))
      if (response.success) {
        this.sellers_disponibles = response.sellers
      }
    }

  }

}
