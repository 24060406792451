<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

        </div>
        <div class="login-main">
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/logo-honda-motos.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo-honda-motos.png" alt="looginpage">
            </a>
            <form class="theme-form" [formGroup]="loginForm">
              <!-- <h4>Login</h4> -->
              <p>Ingrese su correo electrónico y contraseña</p>
              <div class="form-group">
                <label class="col-form-label">Correo electrónico</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                  formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">
                  Correo electrónico es requerido
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">
                  Correo electrónico inválido
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required=""
                  placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                  class="text text-danger mt-1">
                  Contraseña requerida
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div> -->
                <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                <button class="btn btn-primary d-block w-100" [class.loader--text]="userService.showLoader"
                  [disabled]="!loginForm.valid || userService.showLoader" (click)="login()"
                  type="button"><span>{{ userService.showLoader ? '' : 'Ingresar' }}</span>
                </button>
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6> 
              <div class="social mt-4">
                <div class="btn-showcase">
                   <a class="btn btn-light" (click)="loginGoogle()" target="_blank">
                    <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google +
                  </a> 
                  <a class="btn btn-light" (click)="loginFacebook()" target="_blank">
                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook
                  </a> 
                  <a class="btn btn-light" (click)="loginTwitter()" target="_blank">
                    <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a> 
                </div>
              </div> -->
              <!-- <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ms-2">Create Account</a>
              </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>