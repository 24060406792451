import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DragulaModule } from 'ng2-dragula';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

// import { AuthService } from './shared/services/firebase/auth.service';
import { CampusService } from './shared/services/campus.service'
import { LeadsService } from './shared/services/leads.service'
import { UsersService } from './logueo/users.service'
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { LeadsComponent } from './components/leads/leads.component';
import { SelectTiendaComponent } from './components/select-tienda/select-tienda.component';

import { DataTablesModule } from "angular-datatables";
import { LeadDetalleComponent } from './components/lead-detalle/lead-detalle.component';
import { ReportesComponent } from './reportes/reportes.component';
import { AsesorSelectTiendaComponent } from './asesor-select-tienda/asesor-select-tienda.component';
import { ChartModule } from 'angular-highcharts';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RegistrarPedidoComponent } from './components/registrar-pedido/registrar-pedido.component';
import { ColaDeRequerimientoComponent } from './components/cola-de-requerimiento/cola-de-requerimiento.component';
import { RegistrosComponent } from './components/registros/registros.component';
import { LeadWhatsappComponent } from './components/lead-whatsapp/lead-whatsapp.component';
import { LeadMailComponent } from './components/lead-mail/lead-mail.component';
import { UsersComponent } from './components/users/users.component';
import { UsersDescansoComponent } from './components/users-descanso/users-descanso.component';
import { UsersActividadComponent } from './components/users-actividad/users-actividad.component';
import { ConcesionariosComponent } from './components/concesionarios/concesionarios.component';
import { DashboardSesionComponent } from './components/dashboard-sesion/dashboard-sesion.component';
import { ModalAddConcesionarioComponent } from './components/modales-generados/modal-add-concesionario/modal-add-concesionario.component';
import { VincularUsuarioConcesionarioComponent } from './components/modales-generados/vincular-usuario-concesionario/vincular-usuario-concesionario.component';
import { SendCotizacionComponent } from './components/modales-generados/send-cotizacion/send-cotizacion.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { LeadHistoryInfoComponent } from './components/modales-generados/lead-history-info/lead-history-info.component';
import { CreateLeadComponent } from './create-lead/create-lead.component';
import { ModalCreateLeadComponent } from './modal-create-lead/modal-create-lead.component';
import { ModalCreateUserComponent } from './modal-create-user/modal-create-user.component';
import { JefeSelectTiendaComponent } from './jefe-select-tienda/jefe-select-tienda.component';
import { ChangeStoreComponent } from './change-store/change-store.component';
import { ModalReasignarLeadComponent } from './modal-reasignar-lead/modal-reasignar-lead.component';
import { ModalReasignarmasivoLeadComponent } from './modal-reasignarmasivo-lead/modal-reasignarmasivo-lead.component';
import { DashboardUsuariosHoraActividadComponent } from './dashboard-usuarios-hora-actividad/dashboard-usuarios-hora-actividad.component';
import { DashboardUsuariosDiasConexionComponent } from './dashboard-usuarios-dias-conexion/dashboard-usuarios-dias-conexion.component';
import { DashboardHistorialUsuarioActividadesComponent } from './dashboard-historial-usuario-actividades/dashboard-historial-usuario-actividades.component';
import { DashboardUsuarioTiempoPromedioComponent } from './dashboard-usuario-tiempo-promedio/dashboard-usuario-tiempo-promedio.component';
import { DashboardUsuarioPorcentajesComponent } from './dashboard-usuario-porcentajes/dashboard-usuario-porcentajes.component';
import { DashboardUsuarioCantidadesLeadComponent } from './dashboard-usuario-cantidades-lead/dashboard-usuario-cantidades-lead.component';
import { DashboardCantidadLeadPorStatusComponent } from './dashboard-cantidad-lead-por-status/dashboard-cantidad-lead-por-status.component';
import { DashboardCantidadLeadPorFuenteComponent } from './dashboard-cantidad-lead-por-fuente/dashboard-cantidad-lead-por-fuente.component';
import { DashboardLeadsPorConcesionarioComponent } from './dashboard-leads-por-concesionario/dashboard-leads-por-concesionario.component';
import { DashboardUserComparativaComponent } from './dashboard-user-comparativa/dashboard-user-comparativa.component';
import { DashboardModelosCotizadosComponent } from './dashboard-modelos-cotizados/dashboard-modelos-cotizados.component';
import { DashboardModelosCotizadosDetalleModeloComponent } from './dashboard-modelos-cotizados-detalle-modelo/dashboard-modelos-cotizados-detalle-modelo.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeadsComponent,
    SelectTiendaComponent,
    LeadDetalleComponent,
    ReportesComponent,
    AsesorSelectTiendaComponent,
    RegistrarPedidoComponent,
    ColaDeRequerimientoComponent,
    RegistrosComponent,
    LeadWhatsappComponent,
    LeadMailComponent,
    UsersComponent,
    UsersDescansoComponent,
    UsersActividadComponent,
    ConcesionariosComponent,
    DashboardSesionComponent,
    ModalAddConcesionarioComponent,
    VincularUsuarioConcesionarioComponent,
    SendCotizacionComponent,
    LeadHistoryInfoComponent,
    CreateLeadComponent,
    ModalCreateLeadComponent,
    ModalCreateUserComponent,
    JefeSelectTiendaComponent,
    ChangeStoreComponent,
    ModalReasignarLeadComponent,
    ModalReasignarmasivoLeadComponent,
    DashboardUsuariosHoraActividadComponent,
    DashboardUsuariosDiasConexionComponent,
    DashboardHistorialUsuarioActividadesComponent,
    DashboardUsuarioTiempoPromedioComponent,
    DashboardUsuarioPorcentajesComponent,
    DashboardUsuarioCantidadesLeadComponent,
    DashboardCantidadLeadPorStatusComponent,
    DashboardCantidadLeadPorFuenteComponent,
    DashboardLeadsPorConcesionarioComponent,
    DashboardUserComparativaComponent,
    DashboardModelosCotizadosComponent,
    DashboardModelosCotizadosDetalleModeloComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    DataTablesModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    NgxChartsModule,
    NgbModule,
    ChartModule,
    NgxDropzoneModule,
    ToastrModule.forRoot(),
    DragulaModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    //     // for HttpClient use:
    LoadingBarHttpClientModule,
    //     // for Router use:
    LoadingBarRouterModule,
    //     // for Core use:
    LoadingBarModule
  ],
  providers: [UsersService, AdminGuard, SecureInnerPagesGuard, CookieService, CampusService, LeadsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
