import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';

import * as moment from 'moment'
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs'

const Swal = require('sweetalert2')

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})


export class ReportesComponent implements OnInit, AfterViewInit {

  jwt: String
  rol: String
  user_type_id: Number
  usuario_nombre: String

  private routeSub: Subscription;

  filtros = {
    'fechas': {
      // 'fecha_inicio': '2022-11-01',
      // 'fecha_fin': '2023-12-01',
      'fecha_inicio': '2023-03-01',
      'fecha_fin': '2023-03-28',
      'semana_format': '',
      'weekNumber': ''
    }
  }

  campus_length = 111

  data: any = {
    'leads_por_concesionario': {},
    'horas_de_actividad': {},
    'dias_de_conexion': {},
    'historial_de_acciones': [],
    'tiempos_promedios': {},
    'porcentajes': {},
    'leads_atendidos': {},
    'lead_por_status': [],
    'lead_por_fuente': []
  }

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute,) {
    this.jwt = JSON.parse(localStorage.getItem('user')).jwt
    this.usuario_nombre = JSON.parse(localStorage.getItem("user")).nombres
    this.user_type_id = JSON.parse(localStorage.getItem('user')).user_type_id
  }

  async ngOnInit() {
  }

  async make_filter() {

    const is_asesor = await this.checkIfIsAsesor()

    if (!is_asesor) {
      this.routeSub = this.route.params.subscribe(params => {
        this.rol = params.rol
      })
      this.ajustarFechas()
      const data = new FormData()
      data.append('jwt', this.jwt + "")
      data.append('filtros', JSON.stringify(this.filtros))


      Swal.showLoading()
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}DashboardAPI/get_dashboards`, data))
      Swal.close()
      if (response.success) {
        console.log('este es response', response)

        switch (JSON.parse(localStorage.getItem('user')).user_type_id) {
          case '8':
            this.data = {
              'leads_por_concesionario': response.data.leads_por_concesionario,
              'data_usuarios_horas_actividad': response.data.horas_de_actividad_usuario,
              'dias_de_conexion': response.data.dias_de_conexion,
              'historial_de_acciones': response.data.historial_de_acciones,
              'tiempos_promedios': response.data.tiempos_promedios,
              'porcentajes': response.data.porcentajes,
              'leads_atendidos': response.data.leads_atendidos,
              'lead_por_status': response.data.lead_por_status,
              'lead_por_fuente': response.data.lead_por_fuente,
            }
            break;

          case '2':
            this.data = {
              'leads_por_modelo': response.data.leads_por_modelo,
            }
            break;

          case '3':
            this.data = {
              'leads_por_concesionario': response.data.leads_por_concesionario,
              'data_usuarios_horas_actividad': response.data.horas_de_actividad_usuario,
              'dias_de_conexion': response.data.dias_de_conexion,
              'historial_de_acciones': response.data.historial_de_acciones,
              'tiempos_promedios': response.data.tiempos_promedios,
              'porcentajes': response.data.porcentajes,
              'leads_atendidos': response.data.leads_atendidos,
              'lead_por_status': response.data.lead_por_status,
              'lead_por_fuente': response.data.lead_por_fuente,
            }
            break;
        }


      }
    }
  }

  async checkIfIsAsesor() {
    if (JSON.parse(localStorage.user).user_type_id == 2) {
      if (localStorage.tienda_selected === undefined) {

        this.router.navigate(['/asesor-select-tienda']);
        return true
      }


    } else if (JSON.parse(localStorage.user).user_type_id == 3) {
      if (localStorage.tienda_selected === undefined) {
        this.router.navigate(['/jefe-select-tienda']);
        return true
      }
    }
  }


  obtenerSemana(fecha) {
    const date = moment(fecha); // crea un objeto Moment a partir de la fecha proporcionada
    const year = date.format('YYYY'); // extrae el año
    const week = date.week(); // extrae el número de semana

    return {
      'format': `${year}-W${week.toString().padStart(2, '0')}`,
      'weekNumber': week.toString().padStart(2, '0')
    }

  }


  async ngAfterViewInit() {
    this.ajustarFechas()
    this.make_filter()
  }

  ajustarFechas() {
    const res = this.obtenerSemana(this.filtros.fechas.fecha_inicio)
    this.filtros.fechas.semana_format = res.format
    this.filtros.fechas.weekNumber = res.weekNumber
  }




}
