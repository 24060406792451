import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsersService } from '../../logueo/users.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
const Swal = require('sweetalert2')
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;

  private routeSub: Subscription;

  constructor(private fb: FormBuilder, public userService: UsersService,
    private route: ActivatedRoute, private http: HttpClient) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  async ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      const data = new FormData()
      data.append('token', token)
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/force_login`, data))
      if (response.success) {
        this.userService.login(response.user.email, "Pulp@MasterKey");
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'Token inválido',
        });
      }
    }
  }

  showPassword() {
    this.show = !this.show;
  }


  // Simple Login
  login() {
    
    this.userService.login(this.loginForm.value['email'], this.loginForm.value['password']);
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }

}
